import React from 'react';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { KeyStatisticsBlockType } from '../../../../../../types/keyStatisticsBlockType';
import { AnimatedCounter } from './AnimatedCounter';

type KeyStatisticsBlockProps = { block: KeyStatisticsBlockType };

export const KeyStatisticsBlock = ({ block }: KeyStatisticsBlockProps) => {
  const { statistics } = block;

  return (
    <Wrapper>
      {statistics.map((item) => (
        <AnimatedCounter item={item} key={`${item._key}_${item.number}`} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border-top: ${nafColor.neutral.neutral2} 1px solid;
  border-bottom: ${nafColor.neutral.neutral2} 1px solid;
  margin: ${spacing.space40} 0;
`;
